import axios from "axios";

/* 
POWERED BY:
https://www.smashingmagazine.com/2020/10/authentication-in-vue-js/
https://www.youtube.com/watch?v=Vd1YwbHrY1Q&list=PLfdtiltiRHWF1jqLcNO_2jWJXj9RuSDvY&t=0s
*/

const state = {
  token: null,
  manageToken: null,
  firstname: null,
  adminid: null,
  newAdmin: null,
  clients: [],
  current_studio_id: null,
  lang: null,
  langset: null,
  avatar: null,
  permissions: [],
  adminType: null,
  admin: [],
};

const getters = {
  /* Getters are functionalities to get the state. It can be used in multiple components to get the current state. 
  The isAuthenticatated function checks if the state.user is defined or null and returns true or false respectively. 
  StatePosts and StateUser return state.posts and state.user respectively value. */

  authenticated(state) {
    return state.token && state.firstname;
  },

  user(state) {
    return state.firstname;
  },

  admin(state) {
    return state.admin;
  },

  adminid(state) {
    return state.adminid;
  },

  token(state) {
    return state.token;
  },

  manageToken(state) {
    return state.manageToken;
  },

  clients(state) {
    return state.clients;
  },

  lang(state) {
    return state.lang;
  },

  current_sid(state) {
    return state.current_studio_id;
  },

  avatar(state) {
    return state.avatar;
  },

  permissions(state) {
    return state.permissions;
  },

  adminType(state) {
    return state.adminType;
  },

  newAdmin(state) {
    return state.newAdmin;
  },
};

const actions = {
  /* Actions are functions that are used to commit a mutation to change the state or can be used to dispatch i.e calls another action. 
  It can be called in different components or views and then commits mutations of our state; */

  async LogIn({ dispatch }, User) {
    let UserCredentials = new FormData();
    UserCredentials.append("loginname", User.get("loginname"));
    UserCredentials.append("password", User.get("password"));
    UserCredentials.append(
      "secret",
      "adminLogin-vCJrgBfcn7ur24nsf9asGHBef85Wy"
    );

    let response = await axios.post("public/sessions", UserCredentials);

    if (response.data.status !== "error") {
      dispatch("attempt", response.data.data);
    }

    // Admin-Daten
    let admin = await axios.get(`admins/${response.data.data.adminId}`, {
      headers: {
        Token: response.data.data.token,
      },
    });
    if (admin.data.status !== "error") {
      dispatch("admin", admin.data.data);
    }
  },

  async admin({ commit }, data) {
    commit("SET_ADMIN", data);
    commit("SET_USER", data.firstname);
    commit("SET_AVATAR", data.avatar);
    commit("SET_NEWADMIN", data.new);
  },

  async attempt({ commit }, data) {
    commit("SET_TOKEN", data.token);
    commit("SET_MANAGETOKEN", data.token);
    commit("SET_ADMINID", data.adminId);
    commit("SET_CLIENTS", data.companies);
    commit("SET_CURRENTCLIENT", data.current_companies_id);
    commit("SET_LANG", data.lang);
    commit("SET_ADMINTYPE", data.type);
    commit("SET_PERMISSIONS", data.permissions);
    commit("SET_NEWADMIN", data.new);
  },

  async LogOut({ commit }) {
    let user = null;
    commit("LogOut", user);
  },

  async ChangeCompany(value, { commit }) {
    commit("SET_CURRENTCLIENT", value);
  },
};

const mutations = {
  SET_ADMIN(state, data) {
    state.admin = data;
  },

  SET_NEWADMIN(state, value) {
    state.newAdmin = value;
  },

  SET_TOKEN(state, token) {
    state.token = token;
  },

  SET_MANAGETOKEN(state, token) {
    state.manageToken = token;
  },

  SET_USER(state, data) {
    state.firstname = data;
  },

  SET_ADMINID(state, data) {
    state.adminid = data;
  },

  SET_CLIENTS(state, data) {
    state.clients = data;
  },

  SET_CURRENTCLIENT(state, data) {
    state.current_studio_id = data;
  },

  SET_LANG(state, data) {
    (state.lang = data), (state.langset = data);
  },

  SET_AVATAR(state, data) {
    state.avatar = data;
  },

  SET_ADMINTYPE(state, data) {
    state.adminType = data;
  },

  SET_PERMISSIONS(state, data) {
    if (data && data.length > 0) {
      const arr = data.split(",");
      state.permissions = arr;
    }
  },

  LogOut(state) {
    /* state.user = null; */
    state.token = null;
    state.manageToken = null;
    /* state.firstname = null;
    state.adminid = null;
    state.clients = [];
    state.current_studio_id = null;
    state.lang = null;
    state.langset = null;
    state.avatar = null;
    state.permissions = [];
    state.adminType = null;
    state.admin = []; */
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
